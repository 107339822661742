<template>
    <b-container fluid>
      <b-row>
        <b-col>
          <page-snippet name="upload-asset" />
          
          <div class="dropzone" 
              v-cloak 
              v-bind:class="{ dropzone: true, dragover: dragover }"
              @drop.prevent="addFile" 
              @dragover.prevent 
              @dragenter="dragover = true" 
              @dragleave="dragover = false"
              @click="openDialog()">
            Click here or drag and drop your audio/artwork here to upload (wav, jpg)
          </div>

          <b-alert v-model="uploadProblemSecs" variant="danger" dismissible>
            {{ uploadProblems }}
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="8">
          <b-card header="Tracks">
            <div v-if="audioFiles.length == 0">
              Upload wav files to add tracks
            </div>

            <div v-if="audioFiles.length > 0">
              <b-table-simple striped>
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Filename</th>
                    <th scope="col">Information</th>
                    <th scope="col">Status</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <draggable v-model="audioFiles" tag="tbody">
                  <tr v-for="(file, index) in audioFiles" :key="index">
                    <td>{{ index + 1}}</td>
                    <td scope="row">{{ file.name }}</td>
                    <td scope="row">
                      <template v-if="file.info && file.info.format == 'wav'">
                        <span :class="{ 'font-weight-bold': true, 'text-success': file.info.bitsPerSample >= 16, 'text-danger': file.info.bitsPerSample < 16 }">
                          {{ file.info.bitsPerSample }}bps
                        </span>
                        /
                        <span :class="{ 'font-weight-bold': true, 'text-success': file.info.sampleRate >= 44100, 'text-danger': file.info.sampleRate < 44100 }">
                          {{ file.info.sampleRate }}Hz
                        </span>
                        /
                        <span :class="{ 'font-weight-bold': true, 'text-success': file.info.channels == 2, 'text-danger': file.info.channels != 2 }">
                          <template v-if="file.info.channels == 1">
                            Mono
                          </template>
                          <template v-if="file.info.channels == 2">
                            Stereo
                          </template>
                          <template v-if="![1,2].includes(file.info.channels)">
                            {{ file.info.channels }}ch
                          </template>
                        </span>
                      </template>

                      <template v-if="!file.info || file.info.format != 'wav'">
                        <span class="text-danger" v-if="file.info && file.info.format">
                          Unsupported format: {{ file.info.format }}
                        </span>
                      </template>
                    </td>
                    <td>
                      <span v-if="file.status != 'uploading'">{{ statusText[file.status] }}</span>
                      <b-progress v-if="file.status == 'uploading'" :value="file.uploadPercentage" :max="100" show-progress />
                    </td>
                    <td>
                      <b-button small @click="removeAudioFile(file)" title="Remove">X</b-button>
                    </td>
                  </tr>
                </draggable>
              </b-table-simple>

              <p class="mt-4">You can drag and drop the tracks in the order you want</p>
            </div>
          </b-card>

          <template v-if="artwork && artwork.status == 'uploadComplete'">
            <b-alert v-model="artworkTooSmall" variant="danger" class="mt-4">
              The artworkfile is smaller than 3000x3000 pixels. Please upload a new file.
            </b-alert>

            <b-alert v-model="artworkTooLarge" variant="danger" class="mt-4">
              The artworkfile is larger than 6000x6000 pixels. Please upload a new file.
            </b-alert>

            <b-alert v-model="artworkNotRgb" variant="danger">
              The artworkfile colorspace is not RGB. Please upload a new file.
            </b-alert>

            <b-alert v-model="artworkPoorQuality" variant="danger">
              The artworkfile quality is lower than 75. Please upload a new file.
            </b-alert>
          </template>

          <b-button class="mt-4" :disabled="!readyToProceed" @click="proceed" variant="primary">
            <span v-if="readyToProceed">Proceed</span>
            <span v-if="audioFiles.length == 0">Add files to proceed</span>
            <span v-if="!readyToProceed && audioFiles.length > 0">You can proceed when the uploads are complete</span>
          </b-button>
        </b-col>

        <b-col>
          <b-card header="Artwork">
            <div v-if="artworkImageSrc == ''">
              Upload RGB JPEG files for your artwork
            </div>

            <div v-if="artworkImageSrc != ''">
              <b-img fluid :src="artworkImageSrc" />

              <p class="mt-4 text-center">{{ statusText[artwork.status] }}</p>
              <b-progress v-if="artwork.status == 'uploading'" :value="artwork.uploadPercentage" :max="100" show-progress />

            </div>
          </b-card>
        </b-col>
      </b-row>

    </b-container>
</template>

<script>
  import PageSnippet from '@/components/PageSnippet'
  import draggable from 'vuedraggable'

  export default {
    name: 'AssetUpload',
    components: {
      PageSnippet,
      draggable
    },
    data () {
      return {
        audioFiles: [],
        artworkFiles: [],
        selectedArtwork: -1,
        artworkImageSrc: '',
        dragover: false,
        uploadProblemSecs: 0,
        uploadProblems: '',
        uploading: false,
        statusText: {
          uploading: "Uploading",
          uploadComplete: "Upload ready",
          uploadError: "Upload error",
          invalidAudio: "Audio invalid",
        }
      }
    },
    props: {
      release: Object,
    },
    watch: {
      selectedArtwork () {
        console.log('artwork changed')
        let reader  = new FileReader();
        let self = this

        let artworkFile = this.artworkFiles[this.selectedArtwork]

        this.$set(this.release, 'Artwork', [
          {
            Type: "FrontCover",
            TemporaryBlobID: artworkFile.sha,
          }
        ]) 

        this.release.Title = artworkFile.name.split('.').slice(0, -1).join('.')

        reader.onload = function(e) {
          self.artworkImageSrc = e.target.result;
        }
        reader.readAsDataURL(artworkFile);
      },
      audioFiles () {
        this.$set(this.release, 'Tracks', [])

        this.audioFiles.forEach((f, index) => {
          const defs = this.$root.$data.fieldDefs

          const track = {
            SourceProviderID: this.release.SourceProviderID,
            SourceSubProviderID: this.release.SourceSubProviderID,
            SourceTermsetID: this.release.DefaultTrackTermSetID,
            DiskSeq: 1,
            TrackSeq: index+1,
            Title: f.name.split('.').slice(0, -1).join('.') ,
            Attribution: '',
            TitleLanguage: '',
            LyricsLanguage: '',
            VersionTitle: '',
            ISRC: '',
            WorkTitle: '',
            Key: '',
            ClassicalCatalogNr: '',
            MovementTitle: '',
            MovieShowName: '',
            CLine: '',
            PLine: '',
            Rights: [
              {
                Territory: this.release.DefaultTerritory != 'none' ? this.release.DefaultTerritory : '',
                AllowAdvertising: defs.Track['Rights.AllowAdvertising'].DefaultValue,
                AllowSubscription: defs.Track['Rights.AllowSubscription'].DefaultValue,
                AllowTransaction: defs.Track['Rights.AllowTransaction'].DefaultValue,
              }
            ],
            AssetFiles: [  { TemporaryBlobID: f.sha } ],
            Attributes: [ { Type: 'OverrideDefaultCasing', 'Value': '1' } ],
            Tags: [ { Type: 'Category', Value: 'Audio' } ]
          }

          // Default values
          for (const [field, val] of Object.entries(this.$root.$data.fieldDefs.Track)) {
            if (val.DefaultValue === null) continue
            

            if (field.startsWith('Tags.')) {
              track.Tags.push({ Type: val.JsonField, Value: val.DefaultValue})
            } else if (field.indexOf('.') == -1) {
              console.log(field)
              track[val.JsonField] = val.DefaultValue
            }
          }

          this.release.Tracks.push(track)
        })
      },
    },
    methods: {
      openDialog () {
        const fileSelector = document.createElement("input")
        fileSelector.setAttribute('type', 'file')
        fileSelector.setAttribute('multiple', 'multiple')
        fileSelector.addEventListener('change', (e) => {
          this.addFile(e, fileSelector.files)
        })
        fileSelector.click()

      },
      addFile (e, files) {
        let invalidFiles = []
        let self = this

        this.dragover = false
        let droppedFiles;

        if (files) {
          droppedFiles = files
        } else {
          droppedFiles = e.dataTransfer.files
        }
        
        if (!droppedFiles) {
          return
        }
        
        ([...droppedFiles]).forEach(f => {
          let reader = new FileReader();
          reader.onload = function (event) {
            crypto.subtle.digest('SHA-384', event.target.result).then(hashBuffer => {
              let hashArray = Buffer.from(new Uint8Array(hashBuffer));
              
              f.sha = hashArray.toString('base64')
              f.sha_hex = hashArray.toString('hex')
              f.status = ''
              f.uploadPercentage = 0
              f.uploadComplete = false
              f.extension = f.name.split('.').pop().toLowerCase()
              
              if (f.extension == 'wav') {
                self.audioFiles.push(f);
              } else if (['jpg'].includes(f.extension)) {
                self.artworkFiles.push(f)
                self.selectedArtwork++
              } else {
                invalidFiles.push(f)
                return
              }

              self.uploadFile(f);
            })
          };

          reader.readAsArrayBuffer(f);        
        });

        if (invalidFiles.length > 0) {
          this.uploadProblemSecs = 5
          this.uploadProblems = 'You have uploaded invalid files ('+ invalidFiles.length +'), these have been ignored';
        }
      },
      updateFile (file) {
        let index = this.audioFiles.findIndex(f => f.sha == file.sha)
        if (index > -1)
          this.$set(this.audioFiles, index, file)
        
        index = this.artworkFiles.findIndex(f => f.sha == file.sha)
        if (index > -1)
          this.$set(this.artworkFiles, index, file)
      },
      uploadFile (file) {
        let formData = new FormData()
        formData.append('file', file)
        file.status = 'uploading'
        let self = this

        this.$http.post('/temporary-blobs/' + file.sha_hex,
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function(progressEvent) {
              var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              file.uploadPercentage = percentCompleted
              self.updateFile(file)
            }
          }
        ).then(function(response) {
          file.info = response.data.Info
          if ((file.extension == 'wav') && (file.info.format != 'wav' || file.info.bitsPerSample < 16 || file.info.sampleRate < 44100 || file.info.channels != 2)) {
            file.status = 'invalidAudio'
          } else {
            file.status = 'uploadComplete'
          }
          self.updateFile(file)
        })
        .catch(function(){
          file.status = 'uploadError'
          self.updateFile(file)
        });
      },
      removeAudioFile(file) {
        this.audioFiles = this.audioFiles.filter(f => {
          return f != file;
        });
      },
      proceed () {
        this.$emit('proceed')
      },
    },
    computed: {
      artwork () {
        return this.artworkFiles[this.selectedArtwork]
      },
      artworkTooSmall () {
        return !(this.artwork.info && (this.artwork.info.width >= 3000 && this.artwork.info.height >= 3000))
      },
      artworkTooLarge () {
        return !(this.artwork.info && (this.artwork.info.width < 6000 && this.artwork.info.height < 6000))
      },
      artworkNotRgb () {
        return !(this.artwork.info && this.artwork.info.colorspace == 'sRGB')
      },
      artworkPoorQuality () {
        return !(this.artwork.info && this.artwork.info.quality >= 75)
      },
      artworkValid () {
        return !this.artworkTooSmall && !this.artworkTooLarge && !this.artworkNotRgb && !this.artworkPoorQuality
      },
      readyToProceed () {
        if (this.audioFiles.length == 0) return false
        if (this.artwork == undefined) return false
        if (this.artwork.status != 'uploadComplete') return false
        if (!this.artworkValid) return false

        return this.audioFiles.every(f => f.status == 'uploadComplete')
      }
    },
    mounted () {

    }
  }
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables';

  .dropzone {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    border: 2px dashed $color-blue;
    border-radius: 5px;
    background-color: #282933;
    margin-bottom: 20px;

    &.dragover {
      border-style: solid;
      background-color: #414353;
    }
  }
</style>